import React from "react";
import "./data.css";

export const userData = () => {
  return (
    <>
      <table>
        
      </table>
    </>
  );
};
